import { Link } from "react-router-dom"

import Variable from "../components/Variable"

import type React from "react"
import type { ActiveServiceMenu, ActiveStylist } from "../api"

type StylistCardProps = {
  children?: React.ReactNode
  error?: boolean
  loading?: boolean
  serviceMenu: ActiveServiceMenu | null
  stylistData?: ActiveStylist
  condensed?: boolean
}

const StylistCard: React.FC<StylistCardProps> = ({
  children,
  error,
  loading,
  serviceMenu,
  stylistData,
  condensed,
}) => {
  return (
    <div className="grid grid-cols-1 gap-4">
      <section aria-labelledby="section-2-title">
        <h2 className="sr-only" id="section-2-title">
          Your service provider
        </h2>
        <div className="overflow-hidden rounded-lg bg-indigo-600 shadow">
          <h3 className="mb-3 p-6 pb-12 text-xl font-semibold leading-6 text-white">
            Your service provider
          </h3>
          {error ? (
            <div>ERROR!</div>
          ) : (
            <>
              <div className="border-t border-dashed border-indigo-500 p-6 pt-0">
                <img
                  className="-mt-8 mb-6 h-16 w-16 rounded-full ring-4 ring-gray-100"
                  src={stylistData?.avatar_url}
                  alt=""
                />
                <p className="truncate text-xl font-medium text-white hover:underline">
                  <Variable isLoading={loading}>
                    <Link to={`/stylist/${stylistData?.uuid}`}>
                      {stylistData?.name}
                    </Link>
                  </Variable>
                </p>
                {!condensed && (
                  <div className="mr-2 mt-3 mb-4">
                    {serviceMenu?.service_categories.length === 0 ? (
                      <p className="text-xs text-white">
                        This service provider hasn’t listed any services.
                      </p>
                    ) : (
                      serviceMenu?.service_categories.map(c =>
                        c.services.map(s => (
                          <p
                            key={s.id}
                            className="mr-3 mb-3 inline-flex rounded-full bg-green-100 px-3 text-xs font-semibold leading-7 text-green-800"
                          >
                            {c.name} / {s.name}: $
                            {(s.cost_cents / 100).toFixed(2)}
                          </p>
                        ))
                      )
                    )}
                  </div>
                )}
                {(loading || stylistData?.bio) && !condensed && (
                  <div className="rounded-lg border bg-white py-4 px-6 text-indigo-800">
                    <h4 className="mb-2 text-lg font-bold">Bio</h4>
                    <p>
                      <Variable isLoading={loading} width="30ch">
                        {stylistData?.bio}
                      </Variable>
                    </p>
                  </div>
                )}
              </div>
              {children}
            </>
          )}
        </div>
      </section>
    </div>
  )
}

export default StylistCard
