import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import ReactGA from "react-ga4"

import "./index.css"
import reportWebVitals from "./reportWebVitals"

import { ClientOnboardingStatus, ClientsService, OpenAPI } from "./api"
import BasicHeader from "./components/BasicHeader"
import Spinner from "./components/Spinner"
import Accept from "./pages/Accept"
import BookingDetail from "./pages/bookings/BookingDetail"
import Bookings from "./pages/bookings/Bookings"
import Home from "./pages/Home"
import Install from "./pages/Install"
import Layout from "./pages/Layout"
import Messages from "./pages/messages/Messages"
import NotFound from "./pages/NotFound"
import Onboarding from "./pages/Onboarding"
import Profile from "./pages/Profile"
import Search from "./pages/Search"
import StylistProfile from "./pages/StylistProfile"
import Gallery from "./pages/Gallery"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

/* This component allows some global actions to happen in a context that
   includes auth0 helpers.  Generally, they should include their own guards to
   run only once per login instead of on every page navigation; or, normally do
   only a trivial amount of work. */
const InitContext: React.FC<{ children: any }> = ({ children }) => {
  /* This sets the callback for the OpenAPI to get the auth0 token.  It can't be
     included directly in the Auth0ProviderWithNavigate component, because
     useAuth0() must execute within the context provided by the Auth0Provider
     component. */
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  OpenAPI.TOKEN = isAuthenticated ? getAccessTokenSilently : undefined

  useEffect(() => {
    if (!isAuthenticated) return

    let sw: ServiceWorker | null = null
    let send: (() => void) | null = null

    navigator.serviceWorker.ready.then(swReg => {
      sw = swReg.active

      getAccessTokenSilently().then(token => {
        send = () => {
          if (sw != null) sw.postMessage({ token })
        }

        navigator.serviceWorker.addEventListener("message", send)
        /* Send pre-emptively in case it requested a token before the listener
           was added. */
        send()
      })
    })

    return () => {
      if (sw != null && send != null) sw.removeEventListener("message", send)
    }
  }, [getAccessTokenSilently, isAuthenticated])

  /* Make sure the user has accepted the terms of service. */
  const [onboardingStatus, setOnboardingStatus] =
    useState<ClientOnboardingStatus>()
  useEffect(() => {
    if (!isAuthenticated) return
    ClientsService.retrieveClientOnboardingStatus().then(setOnboardingStatus)
  }, [isAuthenticated])

  /* Set the user's timezone to match the browser's, if it doesn't already. */
  const [timezoneUpdated, setTimezoneUpdated] = useState(false)
  if (isAuthenticated && !timezoneUpdated) {
    ClientsService.retrieveClientTimezoneSetting().then(setting => {
      const now = DateTime.now()
      if (now.zoneName !== setting.timezone_name) {
        ClientsService.updateClientTimezoneSetting({
          requestBody: { timezone_name: now.zoneName },
        })
      }
    })
    setTimezoneUpdated(true)
  }

  if (isAuthenticated && onboardingStatus == null) {
    return (
      <div
        style={{
          alignContent: "center",
          display: "grid",
          height: "100vh",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Spinner />
      </div>
    )
  }

  if (
    onboardingStatus != null &&
    (!onboardingStatus.has_accepted_tos ||
      !onboardingStatus.notification_methods_set)
  ) {
    return (
      <div className="min-h-96 h-full bg-gray-100">
        <BasicHeader />
        {/* This border forces children's margins to be entirely contained within the
            div, instead of overflowing it to the next forced margin below. */}
        <div style={{ border: "0.01px solid transparent" }}>
          <Onboarding
            onboardingStatus={onboardingStatus}
            setOnboardingStatus={setOnboardingStatus}
          />
        </div>
      </div>
    )
  }

  return children
}

// This comes from https://developer.auth0.com/resources/guides/spa/react/basic-authentication
const Auth0ProviderWithNavigate = ({ children }: { children: any }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain="concihairge.us.auth0.com"
      clientId="DDq2c0S9CcIo787yjGt9yGY1FnBFgUUz"
      redirectUri={window.location.origin}
      audience="https://api.concihairge.com/"
      onRedirectCallback={onRedirectCallback}
    >
      <InitContext>{children}</InitContext>
    </Auth0Provider>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0ProviderWithNavigate>
        <Layout />
      </Auth0ProviderWithNavigate>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "/accept/:invitationUuid",
        element: <Accept />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/bookings",
        element: <Bookings />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/booking-detail/like/:apptId",
        element: <BookingDetail />,
      },
      {
        path: "/booking-detail/:stylistUuid",
        element: <BookingDetail />,
      },
      {
        path: "/messages/:stylistUuid",
        element: <Messages />,
      },
      {
        path: "/install",
        element: <Install />,
      },
      {
        path: "/stylist/:stylistUuid",
        element: <StylistProfile />,
      },
      { path: "/gallery", element: <Gallery /> },
    ],
  },
])

ReactGA.initialize("G-ZSPGPM6DFC")

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

try {
  console.log(
    "register completed",
    navigator.serviceWorker.register("/sw.js", {
      // TODO
      updateViaCache: "none",
    })
  )
} catch (e) {
  console.error("register failed", e)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
