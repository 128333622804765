import { useCallback, useEffect, useState } from "react"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { ClientsService } from "../api"
import { sendGAEvent } from "../util"

import Button from "./Button"

type CheckoutProps = {
  hasPaymentMethod: boolean
  returnUrl?: string
  stylistUuid: string
  subdued: boolean
}

const CheckoutForm: React.FC<CheckoutProps> = ({
  hasPaymentMethod,
  returnUrl,
  stylistUuid,
  subdued,
}) => {
  const stripe = useStripe()

  const doCheckout = useCallback(async () => {
    const session = await ClientsService.createCheckoutSession({
      requestBody: {
        stylist_uuid: stylistUuid,
        return_url: returnUrl,
      },
    })
    if (session.session_id == null) {
      console.error("Didn't get a checkout session")
      return
    }
    stripe?.redirectToCheckout({
      sessionId: session.session_id,
    })
    sendGAEvent("payment", "setup payment method")
  }, [returnUrl, stripe, stylistUuid])

  const text = hasPaymentMethod
    ? "Change payment method"
    : "Setup payment method"

  return (
    <Button
      onClick={doCheckout}
      scheme={subdued && hasPaymentMethod ? "white" : "indigo"}
    >
      {text}
    </Button>
  )
}

class Loading {}
class NetworkError {}
class NoAccount {}

type State =
  | NetworkError
  | NoAccount
  | { stripePromise: ReturnType<typeof loadStripe>; stylistUuid: string }

async function load(stylistUuid: string): Promise<State> {
  let stripeAccount
  try {
    stripeAccount = (await ClientsService.retrieveStylist({ stylistUuid }))
      .stripe_account_id
  } catch (e) {
    return new NetworkError()
  }
  if (stripeAccount == null) return new NoAccount()
  return {
    stripePromise: loadStripe(
      process.env.REACT_APP_STRIPE_PUB_KEY || "key not in build env",
      { stripeAccount }
    ),
    stylistUuid,
  }
}

type ElementProps = {
  hasPaymentMethod: boolean
  isLoading?: boolean
  returnUrl?: string
  stylistUuid?: string
  subdued: boolean
}

const StripeElement: React.FC<ElementProps> = ({
  hasPaymentMethod,
  isLoading,
  returnUrl,
  stylistUuid,
  subdued,
}) => {
  const [state, setState] = useState<State | Loading>(new Loading())

  const loadCallback = useCallback(async () => {
    setState(new Loading())
    if (isLoading || stylistUuid == null) return
    setState(await load(stylistUuid))
  }, [isLoading, stylistUuid])

  useEffect(() => {
    loadCallback()
  }, [loadCallback])

  if (state instanceof Loading) {
    return <div>Loading&hellip;</div>
  } else if (state instanceof NetworkError) {
    return <div>There was an error loading account information.</div>
  } else if (state instanceof NoAccount) {
    return (
      <div>
        Your service provider is not yet ready to receive payments. Please ask
        them to sign in to their Clexi app.
      </div>
    )
  } else {
    return (
      <Elements stripe={state.stripePromise}>
        <CheckoutForm
          hasPaymentMethod={hasPaymentMethod}
          returnUrl={returnUrl}
          stylistUuid={state.stylistUuid}
          subdued={subdued}
        />
      </Elements>
    )
  }
}

export default StripeElement
