// This file is not generated.  However, everthing in the "generated" subdirectory /is/.
// This file simply re-exports everything that's generated, with a little bit of module
// initialization to set the right API endpoint.  This way, it integrates with our
// different build targets, doesn't commit any endpoint into VCS, and is very flexible
// for development needs.

import { DateTime } from "luxon"

import { OpenAPI } from "./generated"

import type {
  AvailableSlot,
  ClientPhoto,
  PaymentMethodForStylist,
  ServiceMenu,
  Stylist,
} from "./generated"

OpenAPI.BASE = process.env.REACT_APP_BASE_API_URL!.replace(/\/$/, "")

export type Coordinates = NonNullable<Stylist["fixed_location_coordinates"]>

export type PaymentMethodDetail = Required<PaymentMethodForStylist>["detail"]

export type ActiveAvailableSlot = AvailableSlot & {
  startDateTime: DateTime
  endDateTime: DateTime
}

export function activateAvailableSlot(s: AvailableSlot): ActiveAvailableSlot {
  return Object.assign(s, {
    startDateTime: DateTime.fromISO(s.start),
    endDateTime: DateTime.fromISO(s.end),
  })
}

export type ActiveStylist = Stylist & {
  availabilityMinDate: DateTime
  availabilityMaxDate: DateTime
}

export function activateStylist(s: Stylist): ActiveStylist {
  const now = DateTime.local()
  const calc_date = (days: number) => now.plus({ days })

  return Object.assign(s, {
    availabilityMinDate: calc_date(s.availability_min_days),
    availabilityMaxDate: calc_date(s.availability_max_days),
  })
}

export type ServiceCategory = ServiceMenu["service_categories"][0]
export type Service = ServiceCategory["services"][0]

export type LinkedService = Service & {
  category: ServiceCategory
}

export type LinkedServiceCategory = ServiceCategory & {
  services: LinkedService[]
}

export type ActiveServiceMenu = ServiceMenu & {
  service_categories: LinkedServiceCategory[]
}

export function activateServiceMenu(m: ServiceMenu): ActiveServiceMenu {
  function linkServices(c: ServiceCategory): LinkedServiceCategory {
    const { id, name, description, services } = c
    return {
      id,
      name,
      description,
      services: services.map(s => ({ category: c, ...s })),
    }
  }

  const service_categories: LinkedServiceCategory[] =
    m.service_categories.map(linkServices)

  return {
    service_categories,
  }
}

export type ImageSize = ClientPhoto["sizes"][0]

export * from "./generated"
