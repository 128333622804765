import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import { sendGAEvent } from "../../util"
import {
  activateServiceMenu,
  activateStylist,
  ClientAppointment,
  ClientsService,
} from "../../api"
import DatePicker from "../../components/DatePicker"
import { DateSectionText } from "../../components/DateSectionText"
import GeoCoder from "../../components/GeoCoder"
import LocationCard from "../../components/LocationCard"
import PaymentCard from "../../components/PaymentCard"
import PreferredDateCard from "../../components/PreferredDateCard"
import ServicesPicker from "../../components/ServicesPicker"
import StylistCard from "../../components/StylistCard"
import {
  useAddress,
  useDateSelection,
  usePaymentMethod,
  usePersistent,
  useServiceSelection,
} from "../../hooks"

import type { ActiveServiceMenu, ActiveStylist, AvailableSlot } from "../../api"

/* Exactly one of apptId and passedStylistUuid must be passed. */
async function load({
  token,
  apptId,
  passedStylistUuid,
}: {
  token: string
  apptId?: string
  passedStylistUuid?: string
}) {
  const apptP =
    apptId == null
      ? null
      : ClientsService.retrieveClientRefreshedAppointment({
          id: apptId.toString(),
        })

  const url = new URL(
    "/api/clients/profile/",
    process.env.REACT_APP_BASE_API_URL
  )
  const profileP = fetch(url, { headers: { Authorization: `Bearer ${token}` } })

  let context = ""
  try {
    context = "the appointment"
    const likeAppt = await apptP

    let stylistUuid = ""
    if (likeAppt != null) {
      stylistUuid = likeAppt.stylist.uuid
    } else if (passedStylistUuid != null) {
      stylistUuid = passedStylistUuid
    } else {
      return { error: "The programmers messed up!" }
    }
    const stylistP = ClientsService.retrieveStylist({ stylistUuid })
    const serviceMenuP = ClientsService.retrieveServiceMenu({ stylistUuid })

    context = "your stylist’s profile"
    const stylistData = activateStylist(await stylistP)
    const serviceMenu = activateServiceMenu(await serviceMenuP)

    context = "your profile"
    const profileData = await (await profileP).json()

    return { likeAppt, profileData, stylistData, serviceMenu }
  } catch (e) {
    return {
      error: `There was an error loading ${context}.  Please try again later.`,
    }
  }
}

const BookingDetail = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { apptId, stylistUuid: passedStylistUuid } = useParams()
  const [booking, setBooking] = useState(false)

  const [busy, setBusy] = useState(true)
  const [error, setError] = useState<string>()
  const [profileData, setProfileData] = useState<any>(null)
  const [likeAppt, setLikeAppt] = useState<ClientAppointment | null>()
  const [stylistData, setStylistData] = useState<ActiveStylist>()
  const [serviceMenu, setServiceMenu] = useState<ActiveServiceMenu>()

  const loadCallback = useCallback(async () => {
    const token = await getAccessTokenSilently()
    const { profileData, likeAppt, stylistData, serviceMenu, error } =
      await load({
        token,
        apptId,
        passedStylistUuid,
      })
    setError(error)
    setProfileData(profileData)
    setLikeAppt(likeAppt)
    setStylistData(stylistData)
    setServiceMenu(serviceMenu)
    setBusy(false)
  }, [apptId, getAccessTokenSilently, passedStylistUuid])

  useEffect(() => {
    loadCallback()
  }, [loadCallback])

  const {
    address,
    addressUnit,
    coordinates,
    changeAddress,
    changeAddressUnit,
  } = useAddress({ profile: profileData })

  /* When we have a model appointment, the stylist is loaded only after the appointment,
     which satisfies useServiceSelection's ordering constraints. */
  const {
    countedServices,
    totalCostCents,
    totalDurationMinutes,
    serviceIds,
    addService,
    removeService,
  } = useServiceSelection({
    stylist: stylistData,
    serviceMenu,
    modelAppointment: likeAppt,
  })

  const [notes, setNotes] = usePersistent("notes", "")
  const [preferredDate, setPreferredDate] = usePersistent<string>(
    "prefDate",
    ""
  )

  const stylistUuid = stylistData?.uuid

  const {
    isLoading: slotsLoading,
    slots,
    selectionIdx: slotIdx,
    setSelectionIdx: setSlotIdx,
    selectionDisabled: slotsDisabled,
  } = useDateSelection({
    stylistUuid: stylistUuid === undefined ? null : stylistUuid,
    durationMinutes: totalDurationMinutes,
    coordinates,
    preferredDate,
  })

  const { paymentMethodIsLoading, paymentMethodError, paymentMethodData } =
    usePaymentMethod({ stylistUuid })

  const readyToBook =
    coordinates &&
    serviceIds.length > 0 &&
    slotIdx != null &&
    !paymentMethodIsLoading &&
    paymentMethodData

  const navigate = useNavigate()

  async function bookAppointment() {
    if (
      address == null ||
      slots == null ||
      slotIdx == null ||
      stylistData == null
    ) {
      return
    }

    const slot = slots[slotIdx]
    const start = slot.start
    setBooking(true)

    let early = false
    let late = false
    for (const s of slots) {
      early ||= s.bias === "EARLY"
      late ||= s.bias === "LATE"
    }

    let bias: AvailableSlot["bias"] = "NONE"
    if (early && late) {
      bias = slot.bias
    }

    await ClientsService.createNewRequest({
      requestBody: {
        stylist_uuid: stylistData.uuid,
        start,
        service_ids: serviceIds,
        description: notes || null,
        street_address: address,
        street_address_unit: addressUnit || null,
        bias,
      },
    })
    sendGAEvent("appointments", "confirm appointment")
    navigate("/bookings")
  }

  const needsAddress = coordinates == null
  const needsPreferredDate = preferredDate === ""
  const needsServices = totalDurationMinutes === 0

  const dateState = () => {
    if (needsAddress || needsPreferredDate || needsServices) return "needsInfo"
    if (slotsLoading) return "loading"
    if (slots != null) {
      if (slots.length === 0) return "empty"
      return "loaded"
    }
    console.error("Unexpected state", { slotsLoading, slots })
    return "error"
  }

  return (
    <div className="container my-8 mx-auto max-w-7xl px-4 pb-12 text-left sm:px-6 lg:px-8">
      <h1 className="mb-6 text-3xl font-bold tracking-tight text-indigo-600">
        New booking
      </h1>
      <div className="mx-auto max-w-7xl pb-12">
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <StylistCard
            stylistData={stylistData}
            serviceMenu={serviceMenu || null}
            loading={busy}
            error={error != null}
            condensed
          />
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <ServicesPicker
              disabled={booking}
              services={countedServices}
              stylist={stylistData}
              serviceMenu={serviceMenu || null}
              totalCostCents={totalCostCents}
              totalDurationMinutes={totalDurationMinutes}
              onAddService={addService}
              onRemoveService={removeService}
            />
            <LocationCard
              fixedLocationAddress={stylistData?.fixed_location_address}
              fixedLocationCoordinates={stylistData?.fixed_location_coordinates}
            />
            <div className="bg-white px-4 pb-5 pt-4 shadow sm:rounded-lg sm:p-6">
              <fieldset>
                <legend className="text-lg font-medium text-gray-900">
                  Details
                </legend>
                <div className="mb-6 mt-4 border-t border-gray-200 pt-4">
                  {address != null && (
                    <label className="block text-sm font-medium text-gray-700">
                      Your address
                      <GeoCoder
                        defaultValue={address}
                        disabled={booking}
                        handleGeocoderValue={changeAddress}
                      />
                    </label>
                  )}
                </div>
                <div className="mb-6">
                  {addressUnit != null && (
                    <label className="block text-sm font-medium text-gray-700">
                      Unit (optional)
                      <input
                        disabled={booking}
                        name="unit"
                        id="unit"
                        value={addressUnit}
                        onChange={e => changeAddressUnit(e.target.value)}
                        className="mt-1 block h-10 w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Apt 2"
                      />
                    </label>
                  )}
                </div>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Notes (optional)
                    <textarea
                      disabled={booking}
                      name="notes"
                      id="notes"
                      rows={5}
                      value={notes}
                      onChange={e => setNotes(e.target.value)}
                      className="mt-1 block w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </label>
                </div>
              </fieldset>
            </div>
            <PreferredDateCard
              loading={busy || stylistData == null}
              value={preferredDate}
              availabilityMinDate={stylistData?.availabilityMinDate}
              availabilityMaxDate={stylistData?.availabilityMaxDate}
              onChange={setPreferredDate}
            />

            <div className="bg-white px-4 pb-5 pt-4 shadow sm:rounded-lg sm:p-6">
              <fieldset>
                <legend className="mb-4 w-full border-b border-gray-200 pb-4 text-lg font-medium text-gray-900">
                  Choose date
                </legend>
                <DateSectionText
                  needsAddress={needsAddress}
                  needsPreferredDate={needsPreferredDate}
                  needsServices={needsServices}
                  state={dateState()}
                  stylistUuid={stylistUuid}
                />
                {slots != null && (
                  <DatePicker
                    disabled={slotsDisabled || booking}
                    loading={slotsLoading}
                    onChange={setSlotIdx}
                    slots={slots}
                    value={slotIdx === null ? undefined : slotIdx}
                  />
                )}
              </fieldset>
            </div>
            <PaymentCard
              error={paymentMethodError}
              isLoading={paymentMethodIsLoading}
              methodData={paymentMethodData}
              returnUrl={window.location.toString()}
              stylistUuid={stylistUuid}
              subdued
            />
            <button
              disabled={!readyToBook}
              className="mr-4 mt-6 block w-full items-center rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-center font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={bookAppointment}
            >
              Confirm appointment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAuthenticationRequired(BookingDetail)
