import React from "react"

import type { DateTime } from "luxon"

import type { ImageSize } from "../../api"

type MessageLineProps = {
  messageText: string | undefined
  messageImageURL: string | undefined
  messageImageSizes: ImageSize[] | undefined
  sentByMe: boolean
  pictureUrl: string
  timestamp: DateTime
}

const MessageLine: React.FC<MessageLineProps> = ({
  messageText,
  messageImageURL,
  pictureUrl,
  sentByMe,
  timestamp,
}) => {
  let imgURL
  if (messageImageURL != null) {
    imgURL = new URL(messageImageURL, window.location.href)
    imgURL.searchParams.set("height", (64).toString())
  }

  return (
    <div
      style={{
        alignItems: "end",
        columnGap: "0.5rem",
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        gridTemplateAreas: sentByMe
          ? "'a text avatar' 'd timestamp f'"
          : "'avatar text c' 'd timestamp f'",
        justifyItems: sentByMe ? "end" : "start",
      }}
    >
      <img
        src={pictureUrl}
        alt="My profile"
        className={`h-6 w-6 rounded-full ${sentByMe ? "order-2" : "order-1"}`}
        style={{
          gridArea: "avatar",
        }}
      />
      <div className="text-sm text-gray-500" style={{ gridArea: "timestamp" }}>
        {timestamp.toRelativeCalendar()}
      </div>
      <div
        className={`text-s max-w-s order-2 inline-block space-y-2 rounded-lg px-4 py-2 ${
          sentByMe
            ? "rounded-br-none bg-indigo-600 text-white"
            : "rounded-bl-none bg-gray-300 text-gray-600"
        }`}
        style={{ gridArea: "text" }}
      >
        {messageText != null ? (
          messageText
        ) : imgURL != null ? (
          <img
            src={imgURL.toString()}
            width="260"
            alt=""
            className="rounded-lg"
          />
        ) : (
          "UNKNOWN MESSAGE TYPE"
        )}
      </div>
    </div>
  )
}

export default MessageLine
