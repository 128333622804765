import React from "react"
import { useParams } from "react-router-dom"
import Button from "../components/Button"
import StylistCard from "../components/StylistCard"
import { useAuth0 } from "@auth0/auth0-react"

import { useCallback, useEffect, useState } from "react"

import { activateServiceMenu, activateStylist, ClientsService } from "../api"

import type { ActiveServiceMenu, ActiveStylist } from "../api"

const retVal = () => ({
  stylist: undefined as ActiveStylist | undefined,
  serviceMenu: null as ActiveServiceMenu | null,
  error: null as string | null,
})

async function load(uuid: string): Promise<ReturnType<typeof retVal>> {
  try {
    const stylistP = ClientsService.retrieveStylist({ stylistUuid: uuid })
    const serviceMenuP = ClientsService.retrieveServiceMenu({
      stylistUuid: uuid,
    })
    const stylist = activateStylist(await stylistP)
    const serviceMenu = activateServiceMenu(await serviceMenuP)

    return { ...retVal(), serviceMenu, stylist }
  } catch (e) {
    console.log("Error fetching data, ", e)
    return {
      ...retVal(),
      error:
        "There was an error loading the stylist’s profile.  Please try again later.",
    }
  }
}

const StylistProfile = () => {
  const { stylistUuid } = useParams()
  const { user } = useAuth0()

  const [busy, setBusy] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [serviceMenu, setServiceMenu] = useState<ActiveServiceMenu | null>(null)
  const [stylistData, setStylistData] = useState<ActiveStylist>()

  const loadCallback = useCallback(async () => {
    if (stylistUuid == null) return
    const { error, serviceMenu, stylist } = await load(stylistUuid)
    setError(error)
    setServiceMenu(serviceMenu)
    setStylistData(stylist)
    setBusy(false)
  }, [stylistUuid])

  useEffect(() => {
    loadCallback()
  }, [loadCallback])

  return (
    <div className="mx-auto max-w-2xl px-4 py-12 sm:px-6 lg:px-8">
      <StylistCard
        stylistData={stylistData}
        serviceMenu={serviceMenu}
        loading={busy}
        error={error != null}
      >
        <>
          {/* We always show the action buttons below, even for anonymous users, so
              that, for example, Stripe's internal reviewers don't get confused thinking
              the real profile is hidden behind a password.  However, we'll show this
              helpful message in that case. */}
          {user == null && (
            <p className="px-6 pb-6 text-white">
              To book an appointment or message this service provider, you need
              an account.
            </p>
          )}
          <div className="border-gray-200 px-6 pb-6">
            <div className="flex flex-wrap gap-4">
              <Button
                isLoading={busy}
                scheme="white"
                to={`/booking-detail/${stylistUuid}`}
              >
                Request new appointment
              </Button>
              <Button scheme="paleIndigo" to={`/messages/${stylistUuid}`}>
                Contact
              </Button>
            </div>
          </div>
        </>
      </StylistCard>
    </div>
  )
}

export default StylistProfile
