import React, { useEffect, useState } from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Slide } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"

import { ClientsService } from "../api"
import Button from "../components/Button"
import Spinner from "../components/Spinner"

import type { ClientPhoto } from "../api"

const Slider = ({
  defaultIndex,
  photos,
}: {
  defaultIndex: number
  photos: ClientPhoto[]
}) => {
  return (
    <Slide defaultIndex={defaultIndex} transitionDuration={600}>
      {photos.map((p, i) => (
        <div key={i} className="each-slide-effect h-screen w-screen">
          <div
            style={{ backgroundImage: `url(${p.url})` }}
            className="h-screen w-screen bg-gray-200 bg-auto bg-center bg-no-repeat"
          />
        </div>
      ))}
    </Slide>
  )
}

const Photos: React.FC = () => {
  const [page, setPage] = useState(1)
  const [busy, setBusy] = useState(true)
  const [photos, setPhotos] = useState<ClientPhoto[]>()
  const [sliderIndex, setSliderIndex] = useState(0)
  const [isOverlayVisible, setOverlayVisible] = useState(false)

  function prev() {
    setPage(Math.max(1, page - 1))
  }

  function next() {
    setPage(page + 1)
  }

  const openSlider = (index: number) => {
    setSliderIndex(index)
    setOverlayVisible(true)
  }

  const closeSlider = () => {
    setOverlayVisible(false)
  }

  useEffect(() => {
    async function work() {
      const data = await ClientsService.listClientPhotos({ page })
      setPhotos(data.results)
      setBusy(false)
    }
    work()
  }, [page])

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-2 gap-4 py-12 md:grid-cols-4">
        {busy ? (
          <Spinner />
        ) : (
          photos != null &&
          photos.map(
            (p, j) =>
              j % 3 === 0 && (
                <div className="flex flex-col gap-4" key={j}>
                  {photos.slice(j, j + 3).map((p, i) => (
                    <div
                      key={i}
                      onClick={() => openSlider(j + i)}
                      className="scale-100 cursor-pointer transition-all duration-300 hover:scale-105"
                    >
                      <img
                        className="h-auto max-w-full rounded-lg object-cover object-center"
                        src={p.url}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )
          )
        )}
      </div>

      {isOverlayVisible && photos != null && (
        <div
          className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-75"
          onClick={closeSlider}
        >
          <div
            className="relative h-full w-full"
            onClick={e => e.stopPropagation()}
          >
            <Slider defaultIndex={sliderIndex} photos={photos} />
            <button
              className="absolute top-6 right-6 z-50 rounded-md bg-indigo-600 px-4 py-2 text-white"
              onClick={closeSlider}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="mb-24 flex justify-between">
        <div>
          <Button disabled={page === 1} onClick={prev}>
            Previous Page
          </Button>
        </div>
        <div>
          <Button onClick={next}>Next Page</Button>
        </div>
      </div>
    </div>
  )
}

export default withAuthenticationRequired(Photos)
