import { DateTime } from "luxon"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { ClientsService } from "../../api"
import { useApi } from "../../hooks"
import { sendGAEvent } from "../../util"

import MessageLine from "./MessageLine"

import type { ClientMessage } from "../../api"

import PhotoUpload from "../../components/PhotoUpload"

type MessageBoxProps = {
  stylistUuid: string
}

type MessageHook = {
  data?: ClientMessage[]
  isLoading: boolean
  error: boolean
}

const MessageBox: React.FC<MessageBoxProps> = ({ stylistUuid }) => {
  const [fetchedMessages, setFetchedMessages] = useState<ClientMessage[]>([])
  const { data: me } = useApi(ClientsService.retrieveClientProfile, null)
  const { data: stylist } = useApi(ClientsService.retrieveStylist, {
    stylistUuid,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  /* TODO: This interface should not be page-based.  It should take a parameter to
     identify a message, and return the last N messages older than that one. */
  let page = 1
  const { data, isLoading, error }: MessageHook = useApi(
    ClientsService.listClientMessages,
    { stylistUuid: stylistUuid }
  )
  const MessagesRef = useRef<any>()

  const updateMessages = useCallback(async () => {
    try {
      const data = await ClientsService.listClientMessages({
        stylistUuid,
        // TODO
        /* count: 50,
         * page */
      })
      setFetchedMessages(data)
      setTimeout(() => {
        MessagesRef.current.scrollTo({
          top: MessagesRef.current.scrollHeight,
          behavior: "smooth",
        })
      }, 300)
    } catch (error) {}
  }, [stylistUuid])

  const sendMessage = useCallback(
    async (message: string) => {
      try {
        await ClientsService.createClientMessage({
          requestBody: {
            recipient_uuid: stylistUuid,
            text: message,
          },
        })
        updateMessages()
      } catch (error) {
        console.log(error)
      }
    },
    [stylistUuid, updateMessages]
  )

  const [locked, setLocked] = useState(false)

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault()
      const message = event.target.message.value
      if (message === "") return
      setLocked(true)
      sendMessage(message)
      event.target.reset()
      setLocked(false)
      sendGAEvent("contact", "send message")
    },
    [sendMessage]
  )

  const handleScroll = useCallback(async () => {
    const position = MessagesRef.current.scrollTop

    if (position === 0) {
      page++
      try {
        const data = await ClientsService.listClientMessages({
          stylistUuid,
          // TODO
          /* count: 50,
           * page */
        })
        setFetchedMessages(data)
      } catch (error) {
        console.log(error)
      }
    }
  }, [page, stylistUuid])

  useEffect(() => {
    const el = MessagesRef.current
    el.addEventListener("scroll", handleScroll, { passive: true })
    return () => el.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  useEffect(() => {
    MessagesRef.current.scrollTo({
      top: MessagesRef.current.scrollHeight,
      behavior: "smooth",
    })
  }, [])

  let messages: ClientMessage[] = data || []

  const postImageFromUpload = useCallback(
    async (uuid: string) => {
      await ClientsService.createClientMessage({
        requestBody: {
          recipient_uuid: stylistUuid,
          image_uuid: uuid,
        },
      })
      updateMessages()
    },
    [stylistUuid, updateMessages]
  )

  if (error) {
    return <div>Error</div>
  }

  return (
    <>
      <div
        ref={MessagesRef}
        id="messages"
        className="scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch flex flex-grow flex-col space-y-3 overflow-y-auto py-3 px-4 sm:px-6 lg:px-8"
      >
        {messages && !isLoading && me && stylist && (
          <>
            {(fetchedMessages.length > 0 ? fetchedMessages : messages).map(
              (item, i) => {
                /* TODO: Generate a key for locally-generated messages. */
                const sentByMe = item.sender_uuid !== stylistUuid
                const pictureUrl: string =
                  (sentByMe ? me.avatar_url : stylist.avatar_url) || ""
                return (
                  <MessageLine
                    key={item.timestamp}
                    messageText={item.text}
                    messageImageURL={item.image_url}
                    messageImageSizes={item.image_sizes}
                    pictureUrl={pictureUrl}
                    sentByMe={sentByMe}
                    timestamp={DateTime.fromISO(item.timestamp || "now")}
                  />
                )
              }
            )}
          </>
        )}

        {isLoading && (
          <div className="flex animate-pulse space-x-4 py-6 pt-0">
            <div className="flex-1 space-y-6 py-1">
              <div className="space-y-6">
                <div className="h-4 rounded bg-gray-200"></div>
                <div className="h-4 rounded bg-gray-200"></div>
                <div className="h-4 rounded bg-gray-200"></div>
                <div className="h-4 rounded bg-gray-200"></div>
                <div className="h-8 w-24 rounded bg-gray-200"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mb-2 border-t-2 border-gray-200 px-4 py-4 sm:mb-0 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit}>
          <div className="relative flex">
            <input
              disabled={locked}
              type="text"
              placeholder="Write your message!"
              name="message"
              className="w-full rounded-md bg-gray-200 py-3 pl-4 text-gray-600 placeholder-gray-600 focus:placeholder-gray-400 focus:outline-none"
            />
            <div className="absolute inset-y-0 right-0 hidden items-center sm:flex">
              <button
                disabled={locked}
                type="button"
                className="mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-500 transition duration-500 ease-in-out hover:bg-gray-300 focus:outline-none"
                onClick={() => setIsModalOpen(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </button>
              <button
                disabled={locked}
                type="submit"
                className="inline-flex items-center justify-center rounded-lg bg-indigo-500 px-4 py-3 text-white transition duration-500 ease-in-out hover:bg-indigo-600 focus:outline-none"
              >
                <span className="font-bold">Send</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="ml-2 h-6 w-6 rotate-90 transform"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              </button>
            </div>
            <div className="xs:flex absolute inset-y-0 right-0 items-center sm:hidden">
              <button
                disabled={locked}
                type="button"
                className="mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-500 transition duration-500 ease-in-out hover:bg-gray-300 focus:outline-none"
                onClick={() => setIsModalOpen(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </button>
              <button
                disabled={locked}
                type="submit"
                className="inline-flex items-center justify-center px-4 py-3 text-white transition duration-500 ease-in-out focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-6 w-6 rotate-90 transform text-gray-600"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>

      <PhotoUpload
        maxSizeMB={10}
        acceptedTypes={["image/jpeg", "image/png", "image/webp"]}
        isOpen={isModalOpen}
        postImageFromUpload={postImageFromUpload}
        onClose={() => setIsModalOpen(false)}
        onSuccess={({ uuid }) => {
          setIsModalOpen(false)
          postImageFromUpload(uuid)
        }}
        onError={error => {
          console.error("Upload failed:", error)
        }}
      />
    </>
  )
}

export default MessageBox
