import React, { useCallback, useEffect, useRef, useState } from "react"

import { ClientsService } from "../api"

import Button from "./Button"

import type { ClientPhoto, ClientPhotoUploadStatus } from "../api"

interface PhotoUploadProps {
  maxSizeMB?: number
  acceptedTypes?: string[]
  isOpen: boolean
  onClose: () => void
  onSuccess?: (photo: { uuid: string }) => void
  onError?: (error: string) => void
  postImageFromUpload: (uuid: string) => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  maxSizeMB = 5,
  acceptedTypes = ["image/jpeg", "image/png", "image/webp"],
  isOpen,
  onClose,
  onSuccess,
  onError,
  postImageFromUpload,
}) => {
  const [activeTab, setActiveTab] = useState("upload") // 'upload' or 'gallery'
  const [preview, setPreview] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [uploadLog, setUploadLog] = useState<string[]>([])
  const [photos, setPhotos] = useState<ClientPhoto[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isNextPage, setIsNextPage] = useState(true)
  const [page, setPage] = useState(1)
  const fileInputRef = useRef<HTMLInputElement>(null)

  function prev() {
    setPage(Math.max(1, page - 1))
  }

  function next() {
    setPage(page + 1)
  }

  const tabs = [
    { name: "Upload New", key: "upload" },
    { name: "Select from existing uploads", key: "gallery" },
  ]

  const addLog = (message: string) => {
    setUploadLog(prev => [...prev, message])
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    // Validation
    if (!acceptedTypes.includes(file.type)) {
      setError("Please select a valid image file")
      return
    }

    if (file.size > maxSizeMB * 1024 * 1024) {
      setError(`File size must be less than ${maxSizeMB}MB`)
      return
    }

    // Create preview
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreview(reader.result as string)
    }
    reader.readAsDataURL(file)
    setError(null)
  }

  const handleUpload = async () => {
    const file = fileInputRef.current?.files?.[0]
    if (!file) return

    try {
      setIsUploading(true)
      setError(null)

      addLog("Preparing upload...")
      const data = await ClientsService.createClientPhotoUploadRequest({})

      if (!data || !data.upload_url || !data.commit_url) {
        throw new Error(`Invalid URLs received: ${JSON.stringify(data)}`)
      }

      // Step 2: Upload file
      addLog(`Uploading photo...`)
      const uploadResponse = await fetch(data.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      })

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text()
        throw new Error(
          `Upload failed (${uploadResponse.status}): ${errorText}`
        )
      }

      addLog("File upload successful")

      // Step 3: Commit upload
      addLog(data.commit_url)
      const commitResponse = await fetch(data.commit_url, { method: "POST" })

      if (!commitResponse.ok) {
        const errorText = await commitResponse.text()
        throw new Error(
          `Commit failed (${commitResponse.status}): ${errorText}`
        )
      }

      addLog("Photo sent!")

      // Loop until the backend is done processing
      let photoUuid: string
      while (true) {
        const statusRaw = await fetch(data.commit_url)
        const status: ClientPhotoUploadStatus = await statusRaw.json()
        if (status.done) {
          if (status.photo_uuid == null) {
            const msg = "Processing complete but no UUID given"
            addLog(msg)
            throw new Error(msg)
          }
          photoUuid = status.photo_uuid
          break
        }
        await new Promise(resolve => setTimeout(resolve, 1000))
      }

      onSuccess?.({ uuid: photoUuid })

      // Reset component state
      setPreview(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ""
      }
      onClose()
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Upload failed"
      setError(errorMessage)
      onError?.(errorMessage)
      addLog(`Error: ${errorMessage}`)
    } finally {
      setIsUploading(false)
    }
  }

  const loadPhotos = useCallback(async (page: number) => {
    try {
      setIsLoading(true)
      const response = await ClientsService.listClientPhotos({ page })

      setPhotos(response.results || [])
    } catch (err) {
      setError("Failed to load photos")
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    async function work() {
      const response = await ClientsService.listClientPhotos({ page })
      setIsNextPage(response.next !== null)
      setPhotos(response.results || [])
    }
    work()
  }, [page])

  useEffect(() => {
    if (isOpen && activeTab === "gallery") {
      loadPhotos(page)
    }
  }, [activeTab, isOpen, loadPhotos, page])

  const renderUploadTab = () => (
    <div className="mt-4">
      <input
        type="file"
        ref={fileInputRef}
        accept={acceptedTypes.join(",")}
        onChange={handleFileSelect}
        className="block w-full text-sm text-gray-500 file:mr-4 file:rounded-md file:border-0 file:bg-indigo-50 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-indigo-600 hover:file:bg-indigo-100"
      />

      {preview && (
        <div className="mt-4 flex flex-col items-center">
          <img
            src={preview}
            alt="Preview"
            className="max-h-64 object-contain"
          />
          <button
            onClick={handleUpload}
            disabled={isUploading}
            className="mt-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300"
          >
            {isUploading ? "Sending..." : "Send Photo"}
          </button>
        </div>
      )}

      {error && <div className="mt-2 text-sm text-red-600">{error}</div>}

      {uploadLog.length > 0 && (
        <div className="mt-4">
          <h4 className="text-sm font-medium text-gray-900">Upload Progress</h4>
          <pre className="mt-2 max-h-40 overflow-auto rounded-md bg-gray-50 p-2 text-xs text-gray-900">
            {uploadLog.join("\n")}
          </pre>
        </div>
      )}
    </div>
  )

  const renderGalleryTab = () => (
    <div className="mt-4">
      {isLoading ? (
        <div className="flex justify-center py-8">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-indigo-600 border-t-transparent"></div>
        </div>
      ) : photos.length === 0 ? (
        <div className="py-8 text-center text-gray-500">
          No photos uploaded yet.
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
          {photos.map((photo, index) => (
            <div
              key={index}
              className="group relative aspect-square cursor-pointer overflow-hidden rounded-lg bg-gray-100"
              onClick={() => {
                postImageFromUpload(photo.uuid)
                onClose()
              }}
            >
              <img
                src={photo.url}
                alt={`Upload ${index + 1}`}
                className="h-full w-full object-cover object-center"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 transition-opacity group-hover:bg-opacity-30"></div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-12 flex justify-between">
        <div>
          {page > 1 && (
            <Button disabled={page === 1} onClick={prev}>
              Recent Photos
            </Button>
          )}
        </div>
        <div>{isNextPage && <Button onClick={next}>Older Photos</Button>}</div>
      </div>
    </div>
  )

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />

        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                Photos
              </h3>

              <div className="mt-4 border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {tabs.map(tab => (
                    <button
                      key={tab.key}
                      onClick={() => setActiveTab(tab.key)}
                      className={classNames(
                        activeTab === tab.key
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>

              {activeTab === "upload" ? renderUploadTab() : renderGalleryTab()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotoUpload
