import LocationLink from "./LocationLink"

import type { Coordinates } from "../api"

type LocationCardProps = {
  fixedLocationAddress: string | null | undefined
  fixedLocationCoordinates: Coordinates | null | undefined
}

const LocationCard: React.FC<LocationCardProps> = ({
  fixedLocationAddress,
  fixedLocationCoordinates,
}) => {
  return (
    <div className="bg-white px-4 pb-5 pt-4 shadow sm:rounded-lg sm:p-6">
      <h2 className="text-lg font-medium text-gray-900">Location</h2>
      <div className="mb-6 mt-4 border-t border-gray-200 pt-4 text-gray-500">
        {fixedLocationAddress == null || fixedLocationCoordinates == null ? (
          <p>
            The service will happen at <strong>your home address</strong>. Your
            service provider will come to you.
          </p>
        ) : (
          <>
            <p className="mb-4">
              The service will happen at your{" "}
              <strong>service provider’s address</strong>:
            </p>
            <p>
              <LocationLink
                fixedLocationAddress={fixedLocationAddress}
                fixedLocationCoordinates={fixedLocationCoordinates}
              />
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default LocationCard
