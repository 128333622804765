import { useCallback, useEffect, useState } from "react"

import { ClientsService } from "../../api"

import type React from "react"

import type { Stylist as StylistType } from "../../api"

type StylistProps = {
  stylistUuid: string
}

async function load({ stylistUuid }: StylistProps) {
  return await ClientsService.retrieveStylist({ stylistUuid })
}

const Stylist: React.FC<StylistProps> = ({ stylistUuid }) => {
  const [data, setData] = useState<StylistType>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<any>()

  const loadHook = useCallback(async () => {
    try {
      setData(await load({ stylistUuid }))
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }, [stylistUuid])

  useEffect(() => {
    loadHook()
  }, [loadHook])

  return (
    <div className="flex justify-between border-b-2 border-gray-200 py-3 px-4 sm:items-center sm:px-6 lg:px-8">
      {data != null && (
        <div className="relative flex items-center space-x-4">
          <div className="relative">
            <img
              src={data.avatar_url}
              alt=""
              className="h-8 w-8 rounded-full sm:h-16 sm:w-16"
            />
          </div>
          <div className="flex flex-col leading-tight">
            <div className="flex items-center text-xl">
              <span className="mr-3 mb-0 text-gray-700">{data.name}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Stylist
